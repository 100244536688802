// @flow
import {createMuiTheme} from 'ui';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {lighten} from '@material-ui/core/styles/colorManipulator';

export const font =
  '"Work Sans", Helvetica, Arial, "Lucida Grande", sans-serif';

export const breakpoints = createBreakpoints({
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 1,
    sm: 600,
    md: 960,
    lg: 1160,
    xl: 1920,
  },
});

export const colors = {
  white: '#fff',
  black: '#000',

  grey1: '#333333',
  grey2: '#555555',
  grey3: '#777777',
  grey4: '#b1b1b1',
  grey5: '#d8d8d8',
  grey6: '#e5e5e5',
  grey7: '#f1f1f1',
  grey8: '#f9f9f9',

  blue1: '#06234e',
  blue2: '#384f71',
  blue3: '#8291a6',
  blue4: '#b4bdc9',
  blue5: '#dadee5',

  purple1: '#4700e6',
  purple2: '#6c33eb',
  purple3: '#9166f0',
  purple4: '#b599f5',
  purple5: '#f9f7fe',

  turquoise1: '#15a19b',
  turquoise2: '#33bfbc',
  turquoise3: '#7fd7d5',
  turquoise4: '#b2e7e5',
  turquoise5: '#d9f3f3',

  red1: '#e54304',
  red2: '#ea6936',
  red3: '#f2a181',
  red4: '#f7c6b3',
  red5: '#fbe3da',
  red6: '#fdf1ec',

  green1: '#008b00',
  green2: '#33a233',
  green3: '#7fc57f',
  green4: '#b2dcb2',
  green5: '#d9eed9',

  orange1: '#f57f17',
};

export type ColorName = $Keys<typeof colors>;
export type Color = $Values<typeof colors>;

const palette = {
  background: {
    default: colors.white,
    paper: colors.white,
  },
  primary: {
    light: colors.purple3,
    main: colors.purple1,
    dark: colors.purple1,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.blue1,
    contrastText: colors.white,
  },
  error: {
    main: colors.red1,
  },
  text: {
    primary: colors.grey1,
    secondary: colors.grey2,
    disabled: colors.grey3,
    hint: colors.grey2,
  },
  action: {
    active: colors.grey1,
  },
};

const unit = 8;
export const spacing = {
  unit,
  gutterWidth: unit * 3,
  columnWidth: unit * 7,
  maxContentWidth: 1248,
  minScreenWidth: 320,
  outerPadding: {
    xs: unit * 2.5,
    sm: unit * 4,
    mdUp: unit * 5.5,
  },
  headerPadding: {
    xs: 20,
    sm: 24,
    md: 32,
    lgUp: 54,
  },
  bottomPadding: {
    xs: 90,
    sm: 90,
    md: 180,
    lgUp: 240,
  },
  appBarHeight: {
    smDown: 72,
    md: 88,
    lgUp: 96,
  },
  logoWidth: {
    smDown: 88,
    md: 112,
    lgUp: 128,
  },
};

export const media = {
  touchScreen: '@media (hover: none)',
  iOSSafari: '@supports (-webkit-overflow-scrolling: touch)',
  macSafari:
    '@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill)',
  ie10and11:
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
  edge12: '@supports (-ms-accelerator:true)',
};

// creates a single css box-shadow value
export function createShadow(
  umbra: [number, number, number, number, number],
  penumbra: [number, number, number, number, number],
  ambient: [number, number, number, number, number],
): string {
  return [
    `${umbra[0]}px ${umbra[1]}px ${umbra[2]}px ${umbra[3]}px rgba(0, 0, 0, ${
      umbra[4]
    })`,
    `${penumbra[0]}px ${penumbra[1]}px ${penumbra[2]}px ${
      penumbra[3]
    }px rgba(0, 0, 0, ${penumbra[4]})`,
    `${ambient[0]}px ${ambient[1]}px ${ambient[2]}px ${
      ambient[3]
    }px rgba(0, 0, 0, ${ambient[4]})`,
  ].join(',');
}

// modeled after material-ui, creates a list of shadows by elevation
export function createShadows(
  umbraOpacity: number,
  penumbraOpacity: number,
  ambientOpacity: number,
): string[] {
  return [
    'none',
    createShadow(
      [0, 0, 3, 0, umbraOpacity],
      [0, 1, 1, 0, penumbraOpacity],
      [0, 2, 1, -1, ambientOpacity],
    ),
    createShadow(
      [0, 0, 5, 0, umbraOpacity],
      [0, 2, 2, 0, penumbraOpacity],
      [0, 3, 1, -2, ambientOpacity],
    ),
    createShadow(
      [0, 0, 8, 0, umbraOpacity],
      [0, 3, 4, 0, penumbraOpacity],
      [0, 3, 3, -2, ambientOpacity],
    ),
    createShadow(
      [0, 1, 4, -1, umbraOpacity],
      [0, 4, 5, 0, penumbraOpacity],
      [0, 1, 10, 0, ambientOpacity],
    ),
    createShadow(
      [0, 2, 5, -1, umbraOpacity],
      [0, 5, 8, 0, penumbraOpacity],
      [0, 1, 14, 0, ambientOpacity],
    ),
    createShadow(
      [0, 2, 5, -1, umbraOpacity],
      [0, 6, 10, 0, penumbraOpacity],
      [0, 1, 18, 0, ambientOpacity],
    ),
    createShadow(
      [0, 3, 5, -2, umbraOpacity],
      [0, 7, 10, 1, penumbraOpacity],
      [0, 2, 16, 1, ambientOpacity],
    ),
    createShadow(
      [0, 4, 5, -3, umbraOpacity],
      [0, 8, 10, 1, penumbraOpacity],
      [0, 3, 14, 2, ambientOpacity],
    ),
    createShadow(
      [0, 4, 6, -3, umbraOpacity],
      [0, 9, 12, 1, penumbraOpacity],
      [0, 3, 16, 2, ambientOpacity],
    ),
    createShadow(
      [0, 5, 6, -3, umbraOpacity],
      [0, 10, 14, 1, penumbraOpacity],
      [0, 4, 18, 3, ambientOpacity],
    ),
    createShadow(
      [0, 5, 7, -4, umbraOpacity],
      [0, 11, 15, 1, penumbraOpacity],
      [0, 4, 20, 3, ambientOpacity],
    ),
    createShadow(
      [0, 6, 8, -4, umbraOpacity],
      [0, 12, 17, 2, penumbraOpacity],
      [0, 5, 22, 4, ambientOpacity],
    ),
    createShadow(
      [0, 6, 8, -4, umbraOpacity],
      [0, 13, 19, 2, penumbraOpacity],
      [0, 5, 24, 4, ambientOpacity],
    ),
    createShadow(
      [0, 6, 9, -4, umbraOpacity],
      [0, 14, 21, 2, penumbraOpacity],
      [0, 5, 26, 4, ambientOpacity],
    ),
    createShadow(
      [0, 7, 9, -5, umbraOpacity],
      [0, 15, 22, 2, penumbraOpacity],
      [0, 6, 28, 5, ambientOpacity],
    ),
    createShadow(
      [0, 7, 10, -5, umbraOpacity],
      [0, 16, 24, 2, penumbraOpacity],
      [0, 6, 30, 5, ambientOpacity],
    ),
    createShadow(
      [0, 7, 11, -5, umbraOpacity],
      [0, 17, 26, 2, penumbraOpacity],
      [0, 6, 32, 5, ambientOpacity],
    ),
    createShadow(
      [0, 8, 11, -5, umbraOpacity],
      [0, 18, 28, 2, penumbraOpacity],
      [0, 7, 34, 6, ambientOpacity],
    ),
    createShadow(
      [0, 8, 12, -6, umbraOpacity],
      [0, 19, 29, 2, penumbraOpacity],
      [0, 7, 36, 6, ambientOpacity],
    ),
    createShadow(
      [0, 9, 13, -6, umbraOpacity],
      [0, 20, 31, 3, penumbraOpacity],
      [0, 8, 38, 7, ambientOpacity],
    ),
    createShadow(
      [0, 9, 13, -6, umbraOpacity],
      [0, 21, 33, 3, penumbraOpacity],
      [0, 8, 40, 7, ambientOpacity],
    ),
    createShadow(
      [0, 9, 14, -6, umbraOpacity],
      [0, 22, 35, 3, penumbraOpacity],
      [0, 8, 42, 7, ambientOpacity],
    ),
    createShadow(
      [0, 10, 14, -7, umbraOpacity],
      [0, 23, 36, 3, penumbraOpacity],
      [0, 9, 44, 8, ambientOpacity],
    ),
    createShadow(
      [0, 10, 15, -7, umbraOpacity],
      [0, 24, 38, 3, penumbraOpacity],
      [0, 9, 46, 8, ambientOpacity],
    ),
  ];
}

const defaultUmbraOpacity = 0.05;
const defaultPenumbraOpacity = 0.035;
const defaultAmbientOpacity = 0.03;

export const shadows = createShadows(
  defaultUmbraOpacity,
  defaultPenumbraOpacity,
  defaultAmbientOpacity,
);

const buttonUmbraOpacity = 0.15;
const buttonPenumbraOpacity = 0.105;
const buttonAmbientOpacity = 0.09;

export const buttonShadows = createShadows(
  buttonUmbraOpacity,
  buttonPenumbraOpacity,
  buttonAmbientOpacity,
);

export const disabledButton = {
  cursor: 'default',
  boxShadow: 'none',
  color: 'rgba(0, 0, 0, 0.26)',
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
};

export const buttonText = {
  fontSize: 14,
  letterSpacing: 1,
  fontWeight: 600,
  textTransform: 'uppercase',
};

export const theme = createMuiTheme({
  breakpoints,
  shadows,
  palette,
  typography: {
    fontFamily: font,
    fontSize: 16,
  },
  // global props overrides
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  // global style overrides
  overrides: {
    // on ems, rems, and px:
    // https://hackernoon.com/rems-and-ems-and-why-you-probably-dont-need-them-664b9ce1e09f
    MuiTypography: {
      root: {
        'a&, & a': {
          '&:not(:visited)': {
            color: colors.blue1,
          },
          '&:visited': {
            color: colors.purple1,
          },
        },
      },
      // do not add colors to Typography styles, color should be set via the `color` prop
      h1: {
        letterSpacing: -8,
      },
      h2: {
        letterSpacing: -4,
      },
      h3: {
        letterSpacing: -3,
      },
      h4: {
        letterSpacing: -2,
      },
      h5: {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: -0.5,
      },
      h6: {
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: -1,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: -0.5,
      },
      body1: {
        fontSize: 14,
        fontWeight: 400,
      },
      body2: {
        letterSpacing: -0.2,
      },
      caption: {},
      button: {
        ...buttonText,
      },
      alignLeft: {},
      alignCenter: {},
      alignRight: {},
      alignJustify: {},
      noWrap: {},
      gutterBottom: {},
      paragraph: {},
      colorInherit: {},
      colorPrimary: {},
      colorSecondary: {},
      colorTextSecondary: {},
      colorError: {},
    },
    MuiButton: {
      root: {
        lineHeight: '1em',
        textTransform: 'inherit',
        minHeight: 40,
        borderRadius: 3,
        paddingLeft: 24,
        paddingRight: 24,
        '&:hover': {
          [media.touchScreen]: {
            '&:disabled': disabledButton,
          },
        },
        'form:invalid &[type="submit"]': disabledButton,
      },
      sizeSmall: {
        minHeight: 32,
        paddingLeft: 16,
        paddingRight: 16,
      },
      sizeLarge: {
        minHeight: 48,
        paddingLeft: 32,
        paddingRight: 32,
      },
      label: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: font,
        ...buttonText,
        [media.iOSSafari]: {
          width: '100%',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: lighten(palette.primary.main, 0.15),
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: lighten(palette.secondary.main, 0.15),
        },
      },
      outlined: {
        borderWidth: 2,
        borderColor: 'currentColor',
        // set padding to 4px less to offset the border
        paddingLeft: 24,
        paddingRight: 24,
        '&$sizeSmall': {
          paddingLeft: 14,
          paddingRight: 14,
        },
        '&$sizeLarge': {
          paddingLeft: 30,
          paddingRight: 30,
        },
      },
      textPrimary: {
        '&:hover': {
          [media.touchScreen]: {
            '&:disabled': disabledButton,
          },
        },
      },
      textSecondary: {
        '&:hover': {
          [media.touchScreen]: {
            '&:disabled': disabledButton,
          },
        },
      },
      // removed in material UI v4
      // fab: {
      //   boxShadow: buttonShadows[8],
      // },
    },
    MuiIconButton: {
      root: {
        color: colors.grey2,
        '&$disabled': {
          color: colors.grey6,
        },
      },
    },
    MuiInput: {
      root: {
        color: palette.text.primary,
        minHeight: spacing.unit * 6,
        paddingLeft: 14,
        paddingRight: 14,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.grey5,
        transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        borderRadius: 3,
        '&:hover': {
          borderColor: colors.grey4,
        },
        '&$focused': {
          borderColor: colors.grey3,
        },
        '&$disabled': {
          borderColor: [colors.grey6, '!important'],
          color: [colors.grey4, '!important'],
          [media.iOSSafari]: {
            WebkitOpacity: [1, '!important'],
            WebkitTextFillColor: [colors.grey4, '!important'],
          },
          [media.macSafari]: {
            color: [colors.grey3, '!important'],
          },
        },
      },
      multiline: {
        paddingLeft: 14,
        paddingRight: 14,
      },
      underline: {
        '&:after': {
          borderBottomColor: colors.blue1,
        },
      },
      input: {
        fontSize: 16,
        letterSpacing: -0.5,
        '&[type="email"], &[type="password"], &[type="tel"]': {
          height: 'unset',
          [media.ie10and11]: {
            height: 'inherit',
          },
        },
      },

      formControl: {
        'label + &': {
          marginTop: 20,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        // `label&` bumps the specificity so that this renders correctly in snapshots ¯\_(ツ)_/¯
        'label&': {
          transform: 'translate(14px, 36px) scale(1)',
        },
      },
      shrink: {
        fontWeight: 500,
        'label&': {
          transform: 'translate(0, 0) scale(0.75)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.grey3,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        fontSize: 16,
        '&$focused': {
          color: [colors.grey2, '!important'],
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    MuiSelect: {
      root: {
        minWidth: 120,
      },
      select: {
        paddingTop: 12,
        '&:focus': {
          background: 'unset',
        },
      },
    },
    MuiNativeSelect: {
      select: {
        overflow: 'hidden',
        paddingTop: 12,
        '&:focus': {
          background: 'unset',
        },
      },
    },
    MuiList: {
      root: {
        // non-native <Select/> menu's
        '&[role="listbox"]': {
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 3,
        },
      },
    },
    MuiMenu: {
      paper: {
        border: `1px solid ${colors.grey5}`,
        borderRadius: 3,
        boxShadow: shadows[4],
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 16,
        '&:hover': {
          background: colors.grey7,
        },
        '&$selected': {
          backgroundColor: [colors.purple5, '!important'],
          fontWeight: 500,
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 4,
      },
    },
    MuiDialog: {
      paper: {
        [breakpoints.only('xs')]: {
          margin: spacing.unit,
        },
        [breakpoints.up('sm')]: {
          margin: spacing.unit * 2,
        },
      },
      paperScrollPaper: {
        maxHeight: `calc(100% - ${spacing.unit * 4}px)`,
      },
      paperWidthXs: {
        maxWidth: 460,
      },
      paperWidthSm: {
        maxWidth: 700,
      },
      paperWidthMd: {
        maxWidth: 960,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: spacing.unit * 3,
        '& h2': {
          fontWeight: 500,
        },
        [breakpoints.only('xs')]: {
          padding: spacing.unit * 2,
          '& h2': {
            fontSize: 18,
          },
        },
      },
    },
    MuiDialogContent: {
      root: {
        flex: '0 0 auto',
        padding: `0 ${spacing.unit * 3}px ${spacing.unit * 3}px`,
        [breakpoints.only('xs')]: {
          padding: `0 ${spacing.unit * 2}px ${spacing.unit * 2}px`,
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: `0 ${spacing.unit * 3}px ${spacing.unit * 3}px`,
        [breakpoints.only('xs')]: {
          padding: `0 ${spacing.unit * 2}px ${spacing.unit * 2}px`,
        },
      },
    },
    MuiDrawer: {
      paper: {
        // mobile sidebar drawer overlay
        '&[role="document"]': {
          // unbreaks ios safari since the current implementation (100vh)
          // extends beyond the viewport above & below the address & tool bars
          height: '100%',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(6, 35, 78, 0.7)',
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        transition: 'none',
        '&:before': {
          display: 'none',
          transition: 'none',
        },
        '& ~ &:after': {
          top: -1,
          left: 0,
          right: 0,
          height: 2,
          content: '""',
          opacity: 1,
          position: 'absolute',
          backgroundColor: colors.grey6,
        },
        '&$expanded': {
          margin: 0,
        },
        '&$disabled': {
          backgroundColor: colors.grey8,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 0,
        '&$expanded': {
          margin: 0,
          minHeight: 0,
          '& $expandIcon .material-icons': {
            color: palette.primary.main,
          },
        },
      },
      content: {
        margin: `${spacing.unit * 3}px 0`,
        '&$expanded': {
          margin: `${spacing.unit * 3}px 0`,
        },
        '& > p:first-child': {
          fontWeight: 600,
        },
      },
      expandIcon: {
        '& .material-icons': {
          fontSize: 32,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: `${spacing.unit}px ${spacing.unit * 3}px ${spacing.unit *
          4}px`,
      },
    },
  },
});
